/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import Footer from "../organisms/footer"

import "normalize.css"

import Header from "../organisms/header"
import { theme } from "../particles/theme"


import ReactGA from "react-ga4";

ReactGA.initialize("G-M4QFKTD7XK");
ReactGA.send("pageview");

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5rem;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global
        styles={css`
          body,
          html {
            margin: 0;
          }

          html {
            font-size: 62.5%;
            overflow-x: hidden;
          }

          body {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            overflow-x: hidden;
            font-size: 16px;
            width: 100%;
          }

          .display-mobile {
            display: none;
          }

          .no-scroll {
            position: fixed;
            overflow-y: scroll;
            background-image: linear-gradient(
              rgba(50, 50, 50, 0.5),
              rgba(50, 50, 50, 0.5)
            );
          }

          @media (max-width: ${theme.breakpoints.tablet}) {
            .display-mobile {
              display: inline-block;
            }

            .hide-mobile {
              display: none !important;
              margin: unset;
              padding: unset;
            }
          }
        `}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Content>
        <main>{children}</main>
      </Content>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
