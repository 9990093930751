export const theme = {
  colors: {
    bodyFont: "#000000",
    menards: {
      primary: "#008938",
      hover: "#000",
      black: "#000000",
      link: "#000",
    },
  },
  breakpoints: {
    mobileSm: "499px",
    mobileMd: "768px",
    tablet: "1072px",
    desktop: "1200px",
  },
}
